import React from 'react'
import HotItemCard from "./HotItemCard.js"
import "../styles/HotAccessories.css"

const HotAccessories = ({ murabba, murabbaCover, khajoor, khajoorCover, amla_barfi, amla_barfiCover, barfi, barfiCover, mobileAccessories, mobileAccessoriesCover }) => {
    return (
        <div className="HotAccessories">

            <div>
                <img src={murabbaCover || khajoorCover || amla_barfiCover || barfiCover || mobileAccessoriesCover} alt="Cover" />
            </div>


            {/* ----------2nd */}

            <div>
                {murabba && murabba.map((item, index) => (
                    <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

                ))}

                {khajoor && khajoor.map((item, index) => (
                    <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

                ))}

                {amla_barfi && amla_barfi.map((item, index) => (
                    <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

                ))}

                {barfi && barfi.map((item, index) => (
                    <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

                ))}

                <HotItemCard image="https://i01.appmifile.com/webfile/globalimg/in/cms/593181BE-7DB8-15F5-E907-506427B8C9F4.jpg" />
                {/* <HotItemCard image="https://www.stockimagefactory.com/StockImageFactor…0000100223)raw-indian-spices-or-masala-powder.jpg" /> */}
            </div>

        </div>
    )
}

export default HotAccessories



// import React from 'react'
// import HotItemCard from "./HotItemCard.js"
// import "../styles/HotAccessories.css"

// const HotAccessories = ({pickles,picklesCover,spices,spicesCover,murabbas,murabbasCover,pinnis,pinnisiCover}) => {
//     return (
//         <div className="HotAccessories">

//             <div>
//                 <img src={picklesCover || spicesCover || murabbasCover ||pinnisiCover } alt="Cover" />
//             </div>


//             {/* ----------2nd */}

//             <div>
//             { pickles && pickles.map((item,index)=>(
//             <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

//             ))}

//             { spices && spices.map((item,index)=>(
//             <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

//             ))}

//             { murabbas && murabbas.map((item,index)=>(
//             <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

//             ))}

//             { pinnis && pinnis.map((item,index)=>(
//             <HotItemCard key={item.image} name={item.price} image={item.image} index={index} />

//             ))}

// <HotItemCard image="https://i01.appmifile.com/webfile/globalimg/in/cms/593181BE-7DB8-15F5-E907-506427B8C9F4.jpg" />
// {/* <HotItemCard image="https://www.stockimagefactory.com/StockImageFactor…0000100223)raw-indian-spices-or-masala-powder.jpg" /> */}
//             </div>

//         </div>
//     )
// }

// export default HotAccessories