import React from 'react'
import { Link } from 'react-router-dom'
import "../styles/HotAccessoriesMenu.css"

const HotAccessoriesMenu = () => {
  return (
    <div className="HotAccessoriesMenu">
      {/* <Link className="HotAccessoriesLink" to="/pickles">MURABBA GIFT PACK</Link>
      <Link className="HotAccessoriesLink" to="/spices">KHAJOOR GIFT PACK</Link>
      <Link className="HotAccessoriesLink" to="/murabbas">AMLA BARFI GIFT PACK</Link>
      <Link className="HotAccessoriesLink" to="/pinnis">BARFI</Link> */}

      <Link className="HotAccessoriesLink" to="/murabba">MURABBA GIFT PACK</Link>
      <Link className="HotAccessoriesLink" to="/khajoor">KHAJOOR GIFT PACK</Link>
      <Link className="HotAccessoriesLink" to="/amla_barfi">AMLA BARFI GIFT PACK</Link>
      {/* <Link className="HotAccessoriesLink" to="/mobileAccessories">Mobile Accessories</Link> */} 

    </div>
  )
}

export default HotAccessoriesMenu