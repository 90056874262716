import React from 'react'
import NavCard from "./NavCard.js"
import "../styles/NavOptions.css"

// const NavOptions = ({ search, miPhones, redmiPhones, tv, laptop, fitnessAndlifeStyle, amla_barfi, audio, accessories }) => {
const NavOptions = ({ listToRender }) => {

  // const [miPhonesToggle, setMiPhonesToggle] = useState(false);
  // const [redmiPhonessToggle, setRedmiPhonessToggle] = useState(false);
  // const [tvToggle, setTvToggle] = useState(false);
  // const [laptopToggle, setlaptopToggle] = useState(false);
  // const [fitnessToggle, setFitnessToggle] = useState(false);
  // const [amla_barfiToggle, setamla_barfiToggle] = useState(false);
  // const [audioToggle, setAudioToggle] = useState(false);
  // const [accessoriesToggle, setAccessoriesToggle] = useState(false);


  // const [listToRender, setListToRender] = useState(miPhones)

  // useEffect(() => {


  //   if (window.location.pathname === "/miphones") {
  //     return setListToRender(miPhones)
  //   }
  //   if (window.location.pathname === "/redmiphones") {
  //     return setListToRender(redmiPhones)
  //   }
  //   if (window.location.pathname === "/tv") {
  //     return setListToRender(tv)
  //   }
  //   if (window.location.pathname === "/laptops") {
  //     return setListToRender(laptop)
  //   }

  // }, [])

  // useEffect(() => {
  //   if (search !== "") {
  //     const filtered = [];
  //     const a = miPhones.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
  //     console.log(a)
  //     setListToRender(filtered)
  //   }
  // })

  // useEffect(() => {
  //   if (window.location.pathname === "/miphones") {
  //     return setMiPhonesToggle(true)
  //   }
  //   if (window.location.pathname === "/redmiphones") {
  //     return setRedmiPhonessToggle(true)
  //   }
  //   if (window.location.pathname === "/tv") {
  //     return setTvToggle(true)
  //   }
  //   if (window.location.pathname === "/laptops") {
  //     return setlaptopToggle(true)
  //   }
  //   if (window.location.pathname === "/lifestyle") {
  //     return setFitnessToggle(true)
  //   }
  //   if (window.location.pathname === "/amla_barfi") {
  //     return setamla_barfiToggle(true)
  //   }
  //   if (window.location.pathname === "/audio") {
  //     return setRedmiPhonessToggle(true)
  //   }
  //   if (window.location.pathname === "/accessories") {
  //     return setRedmiPhonessToggle(true)
  //   }

  // }, [])


  return (
    <div className='navOptions'>

      {/* {miPhonesToggle ? miPhones.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null}

      {redmiPhonessToggle ? redmiPhones.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null}

      {tvToggle ? tv.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null}

      {laptopToggle ? laptop.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null}

      {fitnessToggle ? fitnessAndlifeStyle.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null}

      {amla_barfiToggle ? amla_barfi.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null}

      {audioToggle ? audio.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null}

      {accessoriesToggle ? accessories.map((item) => (

        < NavCard name={item.name} price={item.price} image={item.image} key={item.image} />
      )) : null} */}

      {listToRender.map((item, i) => (

        < NavCard key={i} name={item.name} price={item.price} image={item.image} />
      ))}
    </div>
  )
}

export default NavOptions