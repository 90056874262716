import './App.css';
// import PreNavbar from './components/PreNavbar';
import Navbar from "./components/Navbar.js"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
// import { BrowserRouter as Router } from "react-router-dom"
import Slider from "./components/Slider.js"
import data from "./data/data"

// import data from "./data/data.json"
// import Offers from "./components/Offers.js"
import Heading from "./components/Heading.js"
import StarProduct from "./components/StarProduct.js"

import HotAccessoriesMenu from "./components/HotAccessoriesMenu.js"
import HotAccessories from './components/HotAccessories.js'

// import ProductReviews from "./components/ProductReviews.js"
// import Videos from "./components/Videos.js"
// import Banner from "./components/Banner.js"
import Footer from "./components/Footer.js"
import NavOptions from "./components/NavOptions.js"
import { useEffect, useState } from 'react';

function App() {

  const [search, setSearch] = useState("");

  const debouncedSearch = () => {
    const filtered = [];
    data.miPhones.forEach((item) => {
      if (item.name.toLowerCase().includes(search.toLowerCase())) {
        filtered.push(item)
      }
    })
    data.redmiPhones.forEach((item) => {
      if (item.name.toLowerCase().includes(search.toLowerCase())) {
        filtered.push(item)
      }
    })
    data.tv.forEach((item) => {
      if (item.name.toLowerCase().includes(search.toLowerCase())) {
        filtered.push(item)
      }
    })
    data.laptop.forEach((item) => {
      if (item.name.toLowerCase().includes(search.toLowerCase())) {
        filtered.push(item)
      }
    })
    setListToRender(filtered)
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const [listToRender, setListToRender] = useState(data.pickles)
  useEffect(() => {
    if (window.location.pathname === "/pickles") {
      return setListToRender(data.pickles)
    }
    if (window.location.pathname === "/spices") {
      return setListToRender(data.spices)
    }
    if (window.location.pathname === "/murabbas") {
      return setListToRender(data.murabbas)
    }
    if (window.location.pathname === "/pinnis") {
      return setListToRender(data.pinnis)
    }

  }, [])

  return (
    <Router>

      {/* <PreNavbar /> */}
      <Navbar search={search} handleSearchChange={handleSearchChange} debouncedSearch={debouncedSearch} />

      {/* <NavOptions search={search} miPhones={data.miPhones} redmiPhones={data.redmiPhones} tv={data.tv} laptop={data.laptop} fitnessAndlifeStyle={data.fitnessAndlifeStyle} amla_barfi={data.amla_barfi} audio={data.audio} accessories={data.accessories} /> */}
      <NavOptions listToRender={listToRender} />

      <Slider start={data.banner.start} />
      {/* <Offers offer={data.offer} /> */}
      <Heading text="STAR PRODUCTS" />
      <StarProduct starProduct={data.starProduct} />
      <Heading text="GIFT PACK'S" />
      <HotAccessoriesMenu />
      <Routes>
        <Route exact path="/murabba" element={<HotAccessories murabba={data.hotAccessories.murabba} murabbaCover={data.hotAccessoriesCover.murabba} />} />
        <Route exact path="/khajoor" element={<HotAccessories khajoor={data.hotAccessories.khajoor} khajoorCover={data.hotAccessoriesCover.khajoor} />} />
        <Route exact path="/amla_barfi" element={<HotAccessories amla_barfi={data.hotAccessories.amla_barfi} amla_barfiCover={data.hotAccessoriesCover.amla_barfi} />} />
        <Route exact path="/barfi" element={<HotAccessories barfi={data.hotAccessories.barfi} barfiCover={data.hotAccessoriesCover.barfi} />} />
        <Route exact path="/mobileAccessories" element={<HotAccessories mobileAccessories={data.hotAccessories.mobileAccessories} mobileAccessoriesCover={data.hotAccessoriesCover.mobileAccessories} />} />
      </Routes>

      {/* <Heading text="PRODUCT REVIEWS" /> */}

      {/* <ProductReviews productReviews={data.productReviews} /> */}

      {/* <Heading text="VIDEOS" /> */}

      {/* <Videos videos={data.videos} /> */}

      {/* <Heading text="IN THE PRESS" /> */}

      {/* <Banner banner={data.banner} /> */}

      <Footer footer={data.footer} />

      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7122950625508!2d77.1078713145597!3d28.638383690568055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03671bd72237%3A0xbcdbefbc6b8894e2!2sHerbal%20Mandir!5e0!3m2!1sen!2sin!4v1659101024920!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      <a
        href="https://wa.me/9999481211"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>

      <a
        href="https://m.facebook.com/Herbal-Mandir-Ayurvedic-Store-1968479030065849/"
        class="facebook_float"
        // target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-facebook-official fa_custom fa-2x"></i>
      </a>

      <a href="tel:+91-921-329-6224"
        class="phone_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class='fa fa-phone green-color'></i>
      </a>

    </Router>
  );
}

export default App;
