import dela from "../images/dela.jpg";
import mangopickle1 from "../images/mango-pickle1.jpeg";
import mangopickle2 from "../images/mango-pickle2.jpeg";
import garlic from "../images/garlic.png";
import ggs from "../images/GGShalgam.png";
import ginger from "../images/Ginger-Pickle.jpg";
import hbharva from "../images/hari_bhrva.jpg";
import lbharva from "../images/lal_bhrva.jpg";
import mirch_cutting from "../images/mirch_cutting.jpg";
import super_mirch from "../images/super_mirch.png";
import mix_achhar from "../images/mixed-aachar.jpg";
import nimbu_khatta from "../images/neembu_khatta.png";
import nimbu_meetha from "../images/neembu_meetha.png";

import alsi_pinni from "../images/alsi_pinni.jpeg";
import assorted_pinni from "../images/Assorted-pinnis.jpeg";
import channa_pinni from "../images/channa_pinni.jpeg";
import atta_pinni from "../images/atta_pinni.jpeg";

import amla_kali from "../images/Amla-Kali-Murabba.jpg";
import amla from "../images/amla.jpg";
import amla_barfi from "../images/amla-barfi.jpg";
import gajar from "../images/Gajar-Murraba.jpg";
import Apple_murabba from "../images/Apple-Murabba.jpg";
import apple_murabba_p from "../images/apple-murabba-pieces.jpg";
import karonda from "../images/karonda.jpg";
import pear_murabba from "../images/pear-murabba-pieces.jpg";
import pineapple from "../images/pineapple.jpg";
import navratan_murabba from "../images/navratan-murabba.png";
import harad from "../images/harad-murraba.jpg";
import bel from "../images/Bel-Murabba.jpg";
import bamboo from "../images/Bamboo-Murabba.jpg";
import papaya from "../images/channa_pinni.jpeg";
import aam from "../images/atta_pinni.jpeg";

import haldi from "../images/haldi-powder.jpg";
import degi_mirch from "../images/degi-mirch.jpg";
import kutti_mirch from "../images/lal-mirch-kutti.jpg";
import dhaniya from "../images/Dhaniya-Sabut.jpg";
import kali_mirch from "../images/kali-mirch.jpg";
import kali_mirch_pissi from "../images/Kali-Mirch-pissi.png";
import parantha_masala from "../images/parantha masala.jpg";
import garam_masala from "../images/garam.jpg";
import chai_masala from "../images/Chai-Masala.jpg";
import kasturi_methi from "../images/Kasthuri-Methi.jpg";
import elaichi from "../images/Elaichi.jpg";
import laung from "../images/laung.jpg";
import jayfal from "../images/Jayfal.jpg";
import pipli from "../images/Pipli.jpg";
import dalchini_powder from "../images/Dalchini-Powder.jpg";
import dalchini_sabut from "../images/Dalchini-Sabut.jpg";
import saunf from "../images/moti-saunf.jpg";
import anardana from "../images/aanardana-powder.jpg";
import methidana from "../images/methi-dana.jpg";
import sarso from "../images/sarso.jpg";
import rai from "../images/rai.jpg";
import kala_namak from "../images/Kala-Namak.jpg";
import ajwain from "../images/Ajwain.jpg";
import jeera from "../images/sabut-jeera.png";
import chat_masala from "../images/Chat-Masala.jpg";
import shahi_paneer from "../images/Shahi-Paneer-Masala.jpg";
import aaloo_puri from "../images/AlooPuri-Masala.jpg";
import kitchen_king from "../images/KitchenKing-Masala.jpg";
import channa from "../images/chana-masala.jpg";
import aamchoor from "../images/Aamchoor-Powder.jpg";
import dal_makhni from "../images/dal-makhani-masala.jpg";
import sendha_namak from "../images/Sendha-Namak.jpg";
import sonth_powder from "../images/Sonth-Powder.jpg";


// import pickle from "../images/pickle_carousel.webp";
// import masala from "../images/masala_carousel.webp";
import masala from "../images/banner-spices.png";
import demoPic from "../demo.png";
import bannerGiftPack from "../images/banner-gift-pack.jpg";
import StarGiftPack from "../images/banner-gift-pack.png";
import bannerNavratanMurabba from "../images/banner-navratan-murabba.png";
// // import sonth_powder from "../images/Sonth-Powder.jpg";
import shop_logo from "../images/shoplogo.jpg"

import khajoor from "../images/paan-khajur.png";
import murabba_star from "../images/main_murabba.jpg";
import khajoor_m from "../images/khjoor-m2.jpg";

import rd_small_m from "../images/rd-small-m.jpeg";
import angoora_m from "../images/angoora-m.jpeg";
import awal_m from "../images/awal.jpeg";

import rd_small_k from "../images/rd-small-k.jpg";
import angoora_k from "../images/khajoor-m.jpg";

import barfi from "../images/barfi.jpeg";
import barfi_mix from "../images/barfi2.jpeg";

const data = {
      // "logo": "https://i01.appmifile.com/webfile/globalimg/pandora/mi-logo.svg",
      "logo": "shop_logo ",
      "pickles": [

            {
                  "name": "RAMKELA AAM",
                  // "price": "from ₹ 29,999",
                  // "image": "https://i02.appmifile.com/368_operator_in/23/04/2021/93d54b25b3efc3b6e87a9d73004de1c3.png?width=140&height=140"
                  "image": mangopickle1
            },
            {
                  "name": "MINI AAM",
                  // "price": "₹ 36,999",
                  // "image": "https://i02.appmifile.com/406_operator_in/15/10/2020/4481bf3c3bb2ce53d9f4ac6ce1d7e312.png?width=140&height=140"
                  "image": mangopickle2
            },
            {
                  "name": "NIMBU KHATTA",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": nimbu_khatta
            },
            {
                  "name": "NIMBU MEETHA",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": nimbu_meetha
            },
            {
                  "name": "HARI BHRVA",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": hbharva
            },
            {
                  "name": "LAL BHRVA",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": lbharva
            },
            {
                  "name": "MIRCH CUTTING",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": mirch_cutting
            },
            {
                  "name": "SUPER (SPICY)",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": super_mirch
            },
            {
                  "name": "MIX",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": mix_achhar
            },
            {
                  "name": "ADRAK",
                  // "price": "₹ 69,999",
                  // "image": "https://i02.appmifile.com/867_operator_in/23/04/2021/3849c44ac78d65621750a114811711f9.png?width=140&height=140"
                  "image": ginger
            },
            {
                  "name": "LEHSUN",
                  // "price": "from ₹ 32,999",
                  // "image": "https://i02.appmifile.com/938_operator_in/15/10/2020/4e6b056c8984ad7031873ae9b5f564a5.png?width=140&height=140"
                  "image": garlic
            },
            {
                  "name": "GAAJAR GOBHI SHALGAM",
                  // "price": "from ₹ 20,999",
                  // "image": "https://i02.appmifile.com/606_operator_in/05/01/2021/3de2b587f140a830fc732855a0a76035.png?width=140&height=140"
                  "image": ggs
            },
            {
                  "name": "DELA",
                  // "price": "from ₹ 39,999",
                  // "image": "https://i02.appmifile.com/52_operator_in/04/03/2021/7db1136dfdc2a1e9fc639b56bc19f17f.png?width=140&height=140"
                  "image": dela
            }


      ],


      "spices": [
            {
                  "name": "HALDI",
                  // "price": "from ₹ 18,999",
                  // "image": "https://i02.appmifile.com/45_operator_in/04/03/2021/890c4652df767017f4857c5e90647d2b.png?width=140&height=140"
                  "image": haldi
            },
            {
                  "name": "DEGI MIRCH",
                  // "price": "from ₹ 15,999",
                  // "image": "https://i02.appmifile.com/52_operator_in/04/03/2021/7db1136dfdc2a1e9fc639b56bc19f17f.png?width=140&height=140"
                  "image": degi_mirch
            },
            {
                  "name": "KUTTI MIRCH",
                  // "price": "from ₹ 14,999",
                  // "image": "https://i02.appmifile.com/828_operator_in/13/05/2021/c52a15e98e975abedb43eb92bc5d3bcb.jpg?width=140&height=140"
                  "image": kutti_mirch
            },
            {
                  "name": "DHANIYA",
                  // "price": "from ₹ 12,499",
                  // "image": "https://i02.appmifile.com/205_operator_in/04/03/2021/0e33cafd058d5340bc4afb329bb84ca3.png?width=140&height=140"
                  "image": dhaniya
            },
            {
                  "name": "KALI MIRCH",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": kali_mirch
            },
            {
                  "name": "KALI MIRCH POWDER",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": kali_mirch_pissi
            },
            {
                  "name": "PARANTHA MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": parantha_masala
            },
            {
                  "name": "GARAM MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": garam_masala
            },
            {
                  "name": "CHAI MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": chai_masala
            },
            {
                  "name": "KASTURI METHI",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": kasturi_methi
            },
            {
                  "name": "ELAICHI",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": elaichi
            },
            {
                  "name": "lAUNG",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": laung
            },
            {
                  "name": "JAYFAL",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": jayfal
            },
            {
                  "name": "PIPLI",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": pipli
            },
            {
                  "name": "DALCHINI POWDER",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": dalchini_powder
            },
            {
                  "name": "DALCHINI",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": dalchini_sabut
            },
            {
                  "name": "SAUNF",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": saunf
            },
            {
                  "name": "ANARDANA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": anardana
            },
            {
                  "name": "METHIDANA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": methidana
            },
            {
                  "name": "SARSO",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": sarso
            },
            {
                  "name": "RAI",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": rai
            },
            {
                  "name": "KALA NAMAK",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": kala_namak
            },
            {
                  "name": "AJWAIN",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": ajwain
            },
            {
                  "name": "JEERA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": jeera
            },
            {
                  "name": "CHAT MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": chat_masala
            },
            {
                  "name": "SHAHI PANEER MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": shahi_paneer
            },
            {
                  "name": "AALOO PURI MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": aaloo_puri
            },
            {
                  "name": "KITCHEN KING MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": kitchen_king
            },
            {
                  "name": "CHANNA MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": channa
            },
            {
                  "name": "AAMCHOOR",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": aamchoor
            },
            {
                  "name": "DAL MAKHNI MASALA",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": dal_makhni
            },
            {
                  "name": "SENDHA NAMAK",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": sendha_namak
            },
            {
                  "name": "SONTH POWDER",
                  // "price": "from ₹ 10,499",
                  // "image": "https://i02.appmifile.com/330_operator_in/17/12/2020/0d20a99f729de496ab65cda4f5389e4d.png?width=140&height=140"
                  "image": sonth_powder
            }
      ],

      "murabbas": [
            {
                  "name": "AMLA KALI",
                  // "price": "₹ 1,24,999",
                  // "image": "https://i02.appmifile.com/620_operator_in/23/04/2021/3c98ae4c034583122a67669c840f249e.jpg?width=140&height=140"
                  "image": amla_kali
            },
            {
                  "name": "AMLA",
                  // "price": "₹ 57,999",
                  // "image": "https://i02.appmifile.com/572_operator_in/16/12/2020/4d07d0c887a4c08fd05ca06e7cd574e3.jpg?width=140&height=140"
                  "image": amla
            },
            {
                  "name": "AMLA BARFI",
                  // "price": "₹ 33,999",
                  // "image": "https://i02.appmifile.com/126_operator_in/24/03/2021/8864007fcaa4d9815f02b827c29f100f.png?width=140&height=140"
                  "image": amla_barfi
            },
            {
                  "name": "CARROT",
                  // "price": "₹ 25,999",
                  // "image": "https://i02.appmifile.com/733_operator_in/07/09/2020/a032f1b0bb0025dcb1c12caab81a9d4b.png?width=140&height=140"
                  "image": gajar
            },
            {
                  "name": "APPLE",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": Apple_murabba
            },
            {
                  "name": "APPLE PIECES",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": apple_murabba_p
            },
            {
                  "name": "PINEAPPLE",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": pineapple
            },
            {
                  "name": "PEAR",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": pear_murabba
            },
            {
                  "name": "CHERRY",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": karonda
            },
            {
                  "name": "NAVRATAN",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": navratan_murabba
            },
            {
                  "name": "HARAD",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": harad
            },
            {
                  "name": "BEL",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": bel
            },
            {
                  "name": "BAMBOO",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": bamboo
            },
            {
                  "name": "PAPAYA",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": papaya
            },
            {
                  "name": "AAM",
                  // "price": "₹ 23,999",
                  // "image": "https://i02.appmifile.com/986_operator_in/01/06/2021/f2b7747ce2bf0365e4414eb323b97ed5.jpg?width=140&height=140"
                  "image": aam
            }




      ],
      "pinnis": [
            {
                  "name": "Alsi Pinni",
                  // "price": "From ₹ 54,999",
                  // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/81DAE22D-023B-1742-5400-32A7760B0962.jpg?width=140&height=140"
                  "image": alsi_pinni
            },
            {
                  "name": "Channa Pinni",
                  // "price": "From ₹ 43,999",
                  // "image": "https://i02.appmifile.com/763_operator_in/19/01/2021/4be770942f3352ad2e7e3a94e5d75a43.jpg?width=140&height=140"
                  "image": channa_pinni
            },
            {
                  "name": "Atta Pinni",
                  // "price": "From ₹ 41,999",
                  // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/9534EF41-E7D9-025C-A287-B4BAFCA4A0F9.jpg?width=140&height=140"
                  "image": atta_pinni
            },
            {
                  "name": "Assorted Pinni",
                  // "price": "₹ 38,999",
                  // "image": "https://i02.appmifile.com/234_operator_in/05/11/2020/5b641713340b4a542b75cf737ed2cd80.png?width=140&height=140"
                  "image": assorted_pinni
            }
            // },
            // {
            //       "name": "Mi Webcam HD",
            //       "price": "₹ 1,099",
            //       "image": "https://i02.appmifile.com/504_operator_in/05/01/2021/d465ac7bca989e33487b4b4f2447c3ea.png?width=140&height=140"
            // }
      ],
      "fitnessAndLifeStyle": [
            {
                  "name": "Mi Watch Revolve",
                  "price": "₹ 9,999",
                  "image": "https://i02.appmifile.com/669_operator_in/29/09/2020/492027bef78ed4c69cfd68c2d299d49f.png?width=140&height=140"
            },
            {
                  "name": "Redmi Watch GPS",
                  "price": "₹ 3,999",
                  "image": "https://i02.appmifile.com/594_operator_in/13/05/2021/7a6d0c6dfdc36b8e65a210c6e40e5171.jpg?width=140&height=140"
            },
            {
                  "name": "Mi Smart Band 5",
                  "price": "₹ 2,499",
                  "image": "https://i02.appmifile.com/867_operator_in/29/09/2020/fbf5b3e1948fc961dba533509c5c4b40.png?width=140&height=140"
            },
            {
                  "name": "Mi Beard Trimmer 1C",
                  "price": "₹ 999",
                  "image": "https://i02.appmifile.com/979_operator_in/06/08/2020/855d6e62a97cb56339126c68de3918e9.png?width=140&height=140"
            },
            {
                  "name": "Mi Athleisure Shoes",
                  "price": "₹ 1,699",
                  "image": "https://i02.appmifile.com/205_operator_in/29/09/2020/e989b9be493148ba6461e8b43d4df81d.png?width=140&height=140"
            }
      ],
      "amla_barfi": [
            {
                  "name": "Mi Robot Vacuum-Mop P",
                  "price": "₹ 24,999",
                  "image": "https://i02.appmifile.com/779_operator_in/22/12/2020/b740f2dbab9a4fef11065926e1aacc42.png?width=140&height=140"
            },
            {
                  "name": "Mi Air Purifier 3",
                  "price": "₹ 10,999",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/D0577E6F-7952-56FD-86EB-7A3EF9FE33C1.jpg?width=140&height=140"
            },
            {
                  "name": "Mi Air Purifier 2C",
                  "price": "₹ 6,999",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/6CFA11D4-6969-C4C8-C8BA-BF3922F82294.jpg?width=140&height=140"
            },
            {
                  "name": "Mi Smart Water Purifier",
                  "price": "₹ 12,999",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/0C7A1A6D-BE84-6D55-17DA-37620E1FA483.jpg?width=140&height=140"
            },
            {
                  "name": "Mi Security Cameras",
                  "price": "From ₹ 1,799",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/373DBE3D-CC06-07ED-0F10-EB090CF9737E.jpg?width=140&height=140"
            }
      ],
      "audio": [
            {
                  "name": "Mi Smart Speaker",
                  "price": "₹ 3,999",
                  "image": "https://i02.appmifile.com/77_operator_in/29/09/2020/842c0032334066f68c60de04c513c6e0.png?width=140&height=140"
            },
            {
                  "name": "Mi True Wireless Earphones 2",
                  "price": "₹ 3,999",
                  "image": "https://i02.appmifile.com/530_updatepdf_in/08/05/2020/dffe54d117f1c15448f6a61933499b20.png?width=140&height=140"
            },
            {
                  "name": "Redmi Earbuds 2C",
                  "price": "₹ 1,499",
                  "image": "https://i02.appmifile.com/722_operator_in/05/01/2021/e82994f46499c08ba408dff5d544745d.png?width=140&height=140"
            },
            {
                  "name": "Mi Outdoor Bluetooth Speaker (5W)",
                  "price": "₹ 1,399",
                  "image": "https://i02.appmifile.com/184_operator_in/27/05/2021/f515132e83f98d32adfd6d4ef5efbd83.png?width=140&height=140"
            },
            {
                  "name": "Redmi Earphones",
                  "price": "₹ 399",
                  "image": "https://i02.appmifile.com/984_operator_in/02/09/2020/35780ad0fbbd75f9ef2d33dc1f75537b.png?width=140&height=140"
            }
      ],
      "accessories": [
            {
                  "name": "Power Banks",
                  "price": "From ₹ 799",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/B0272C02-9066-1A1B-B63C-DE2C2B169C34.jpg?width=140&height=140"
            },
            {
                  "name": "Mi Router 4C",
                  "price": "₹ 999",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/CE0F6208-9AAC-73CB-2F59-130D2E10F999.jpg?width=140&height=140"
            },
            {
                  "name": "Mi Portable Wireless Mouse",
                  "price": "₹ 500",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/C79D1AF1-8FCC-F03A-DC26-F9A1EF509DD4.jpg?width=140&height=140"
            },
            {
                  "name": "Chargers & Cables",
                  "price": "From ₹ 179",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/84A39CC0-85BC-DCFC-1D33-9BD71D1B131A.jpg?width=140&height=140"
            },
            {
                  "name": "Cases & Protectors",
                  "price": "From ₹ 49",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/6D46492D-DB66-A5AF-968C-58443A98FE1E.jpg?width=140&height=140"
            }
      ],
      "banner": {
            "start": [
                  masala, demoPic, bannerGiftPack, bannerNavratanMurabba
            ],
            "end": [
                  {
                        "name": "MI 11X",
                        "description": "What makes the Mi 11X a huge disruptor is its price tag. It comes with what we call “crazy Xiaomi pricing”",
                        "source": "TechPP",
                        "image": "https://i02.appmifile.com/708_operator_in/10/05/2021/2b7fc56c3b3c44f4b11bd9bd2b255749.jpg"
                        // "image":"https://www.stockimagefactory.com/StockImageFactor…0000100223)raw-indian-spices-or-masala-powder.jpg"

                  },
                  {
                        "name": "MI 11X PRO",
                        "description": "It is easily the most affordable device with a Snapdragon 888 chip and a 108-megapixel camera in the market right now",
                        "source": "TechPP",
                        "image": "https://i02.appmifile.com/191_operator_in/10/05/2021/4ccd175347800225dd6b47eb35166e2e.jpg"
                  },
                  {
                        "name": "REDMI NOTE 10 PRO MAX",
                        "description": "The Redmi Note 10 Pro Max’s macro camera captures the tiniest piece of details to stitch together some very good images.",
                        "source": "India Today",
                        "image": "https://i02.appmifile.com/52_operator_in/15/03/2021/7f91bdb2f227f8bd679fa96ac2a9fc1f.jpg"
                  },
                  {
                        "name": "REDMI NOTE 10",
                        "description": "The deep contrasts and the richness that AMOLED brings along add a much-needed dimension to the smartphone.",
                        "source": "TechPP",
                        "image": "https://i02.appmifile.com/107_operator_in/17/03/2021/fa9bab9cbad1629076b04a17a655559c.jpg"
                  },
                  {
                        "name": "MI 10I",
                        "description": "The Mi 10i clicks authentic, true-to-life photos with an abundance of details in every shot.",
                        "source": "91Mobiles",
                        "image": "https://i02.appmifile.com/85_operator_in/11/01/2021/b7600815ae26854c451a39ee88431098.jpg"
                  },
                  {
                        "name": "MI QLED TV 4K",
                        "description": "Redefining Value for Premium TVs",
                        "image": "https://i02.appmifile.com/108_operator_in/13/01/2021/3bfe5123ede27524d616f0bdaf43945a.jpg"
                  },
                  {
                        "name": "MI 10T PRO",
                        "description": "Mi 10T Pro is a clear winner in the premium segment",
                        "source": "FoneArena",
                        "image": "https://i02.appmifile.com/843_operator_in/28/12/2020/226c73c386cf35e47fa0079b3534085f.jpg"
                  },
                  {
                        "name": "REDMI 9 POWER",
                        "description": "In under an hour, the phone was charged around 80 percent plus, which is more than enough battery for two days.",
                        "source": "Indian Express",
                        "image": "https://i02.appmifile.com/779_operator_in/28/12/2020/55bbda7ebbb47f674213dead3c0fefdf.jpg"
                  },
                  {
                        "name": "MI SMART WATER PURIFIER",
                        "description": "Mi Smart Water Purifier: The Epitome Of Minimalism",
                        "source": "Mr.Phone",
                        "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/97E65ECC-E105-9A20-6BD7-8A3235104C57.jpg"
                  },
                  {
                        "name": "MI SOUNDBAR",
                        "description": "We Looked Hard, But There Really Are no Faults.",
                        "source": "TechPP",
                        "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/40F52E34-A946-E8B3-29F2-BBE4A5C7BEEE.jpg"
                  }
            ]
      },
      "offer": [
            {
                  "url": "https://in.event.mi.com/in/offer-with-mi",
                  "image": "https://i02.appmifile.com/31_operator_in/25/04/2021/37529e4f2964c9b26a671172950096ae.jpg?width=398&height=230"
            },
            {
                  "url": "https://in.event.mi.com/in/work-from-amla_barfi-essentials",
                  "image": "https://i02.appmifile.com/884_operator_in/30/05/2021/1e91f02cd9aca02f7caf3c5ddadd2747.jpg?width=398&height=230"
            },
            {
                  "url": "https://xiaomi.giveindia.org/",
                  "image": "https://i02.appmifile.com/761_operator_in/27/04/2021/09489d6a77f96111372718a551d2799d.png?width=398&height=230"
            }
      ],
      "starProduct": [
            // {
            //       // "url": "https://www.mi.com/in/power-banks",
            //       "image": StarGiftPack
            // },
            {
                  // "url": "https://store.mi.com/in/item/3203500001",
                  // "image": "https://i02.appmifile.com/341_operator_in/30/05/2021/e866f96094c058af222f53bc97bcb5ed.jpg?width=612&height=612"
                  "image": amla_barfi
            },
            {
                  // "url": "https://store.mi.com/in/item/3192900017",
                  // "image": "https://i02.appmifile.com/402_operator_in/15/04/2021/d6d00b5cb2a212f4a9a13d4d7c1bdcc1.png?width=612&height=612"
                  "image": khajoor_m
            },
            {
                  // "url": "https://www.mi.com/in/bands-fitness",
                  "image": murabba_star
            }
      ],
      "hotAccessories": {
            "murabba": [
                  {
                        // "name": "Redmi Earbuds S",
                        // "price": "₹ 1,799",
                        // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/BF2A4279-A6D5-B4F0-FE37-633256CDC9FC.jpg"
                        "image": navratan_murabba
                  },
                  {
                        // "name": "Mi Soundbar",
                        // "price": "₹ 4,999",
                        // "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1547107203.76021206.png"
                        "image": rd_small_m
                  },
                  {
                        // "name": "Mi Neckband Bluetooth Earphones",
                        // "price": "₹ 1,599",
                        // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/A1C40BA8-574D-2FA0-62C0-3D7A2C964790.jpg"
                        "image": angoora_m
                  },
                  {
                        // "name": "Mi Super Bass Wireless Headphones",
                        // "price": "₹ 1,799",
                        // "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1563264530.44121333.jpg?width=420&height=420"
                        "image": awal_m
                  }
                  // {
                  //       "name": "Mi Dual Driver In-ear Earphones",
                  //       "price": "₹ 799",
                  //       "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/E794F82C-8858-02F1-7C53-6FB0B4D0C4C6.jpg"
                  // }
            ],
            "khajoor": [
                  {
                        // "name": "Mi Smart Band 4",
                        // "price": "₹ 2,099",
                        // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/0E0F0769-6A69-5820-C8F0-F2B956C65BFA.jpg"
                        "image": rd_small_k
                  },
                  {
                        // "name": "Mi Smart Water Purifier",
                        // "price": "₹ 12,999",
                        // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/BC643CF4-853C-24E4-1EEC-9D4FF614C86D.jpg"
                        "image": angoora_k
                  },
                  // {
                  //       "name": "Mi Watch Revolve",
                  //       "price": "₹ 9,999",
                  //       "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1601293021.06313819.png"
                  // },
                  // {
                  //       "name": "Mi Smart Bedside Lamp 2",
                  //       "price": "₹ 2,499",
                  //       "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/6A656178-B31B-39D1-AC43-9C7EC61C3D84.jpg"
                  // },
                  // {
                  //       "name": "Mi Smart LED Desk Lamp 1S",
                  //       "price": "₹ 2,499",
                  //       "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1574669860.58966823.jpg?width=420&height=420"
                  // }
            ],
            "amla_barfi": [
                  {
                        // "name": "Mi Air Purifier 3",
                        // "price": "₹ 10,999",
                        // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/70A72569-C7EA-BBD7-591E-DC93EC3CDF34.jpg"
                        "image": barfi
                  },
                  {
                        // "name": "Mi Air Purifier 2C",
                        // "price": "₹ 6,999",
                        // "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/7D8FAD13-1E44-9475-3555-949CBF3571AF.jpg"
                        "image": barfi_mix
                  }
                  // {
                  //       "name": "Mi Router 4C",
                  //       "price": "₹ 999",
                  //       "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/FD13B2EB-1AD5-67EE-FF6B-C3E2A0270556.jpg"
                  // },
                  // {
                  //       "name": "Mi Box 4K",
                  //       "price": "₹ 3,499",
                  //       "image": "https://i02.appmifile.com/919_updatepdf_in/15/05/2020/984e626e158fdeb7cc21aff04264773d.png"
                  // },
                  // {
                  //       "name": "Mi LED Wi-Fi Smart Bulb",
                  //       "price": "₹ 1,299",
                  //       "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/441FC065-EEE7-5A64-9CF9-608EF2F82F60.jpg"
                  // }
            ],
            "lifeStyle": [
                  {
                        "name": "Mi Travel Backpack",
                        "price": "₹ 1,999",
                        "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1536381648.16353261.png?width=420&height=420"
                  },
                  {
                        "name": "Mi Athleisure Shoes",
                        "price": "₹ 1,699",
                        "image": "https://i02.appmifile.com/363_operator_in/13/10/2020/c7bde508f128d7b1fd10a224d18a5333.png"
                  },
                  {
                        "name": "Mi Beard Trimmer",
                        "price": "₹ 1,399",
                        "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/CEEE67A3-3578-7048-887D-CFC3D90B5D17.jpg"
                  },
                  {
                        "name": "Mi KN-95 Protective Mask (Pack of 5)",
                        "price": "₹ 399",
                        "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1602500062.48215120.png?width=420&height=420"
                  },
                  {
                        "name": "Mi Portable Electric Air Compressor",
                        "price": "₹ 2,499",
                        "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1592479075.0368979.png?width=420&height=420"
                  },
                  {
                        "name": "Mi T-shirts",
                        "price": "₹ 399 onwards",
                        "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/0D320403-6180-D6D6-9F57-4EDF0FEB58C7.jpg"
                  }
            ],
            "mobileAccessories": [
                  {
                        "name": "Cases & Covers",
                        "price": "₹ 49 onwards",
                        "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1551164869.57618003.jpg"
                  },
                  {
                        "name": "Charger & USB Gadgets",
                        "price": "₹ 179 onwards",
                        "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/5A5AA9AC-41A3-7E86-5922-928B312919B0.jpg"
                  },
                  {
                        "name": "Mi Wireless Power Bank 10000mAh",
                        "price": "₹ 2,499",
                        "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/3CBAACCE-D18A-B241-A4F8-54A4CCE194A5.jpg"
                  },
                  {
                        "name": "10000mAh Mi Power Bank 3i",
                        "price": "₹ 999",
                        "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1600434881.35114664.jpg?width=420&height=420"
                  },
                  {
                        "name": "Redmi SonicBass Wireless Earphones",
                        "price": "₹ 1,299",
                        "image": "https://i01.appmifile.com/v1/MI_18455B3E4DA706226CF7535A58E875F0267/pms_1601900775.75794078.png?width=420&height=420"
                  }
            ]
      },
      "hotAccessoriesCover": {
            // "murabba": "https://i01.appmifile.com/webfile/globalimg/in/cms/500BFA0B-CBEF-5DFF-79A9-E925F9F2FEE9.jpg",
            "murabba": murabba_star,
            // "khajoor": "https://i02.appmifile.com/953_operator_in/17/06/2021/e12dde416676486fed36d0503d5bd65a.jpg",
            "khajoor": khajoor_m,
            // "amla_barfi": "https://i02.appmifile.com/663_operator_in/20/01/2021/0f6ac3c219428b6decbe7fb03d479060.jpg",
            "amla_barfi": amla_barfi,
            "lifeStyle": "https://i02.appmifile.com/677_operator_in/25/01/2021/8163c3c89feb2c83c73b019d5fd50181.jpg",
            "mobileAccessories": "https://i01.appmifile.com/webfile/globalimg/in/cms/801ED76B-614B-5959-E2C3-1EC8E14C0ABD.jpg"
      },
      "productReviews": [
            {
                  "image": "https://i02.appmifile.com/11_operator_in/19/05/2021/981f40e9e6ecc687153fc6a08b7c067d.jpg?width=606&height=252",
                  "review": "PRO DISPLAY. PRO CAMERAS",
                  "name": "REDMI NOTE 10 PRO",
                  "price": "₹ 15,999"
            },
            {
                  "image": "https://i02.appmifile.com/426_operator_in/10/05/2021/ced1802c9525a8939582544bb2822f90.jpg?width=606&height=252",
                  "review": "TRULY SMART. TRULY SAFE. TRULY CONVENIENT.",
                  "name": "MI SMART WATER PURIFIER ",
                  "price": "₹ 12,999"
            }
      ],
      "videos": [
            {
                  "name": "Redmi Note 10 Pro Max",
                  "image": "https://i02.appmifile.com/769_operator_in/26/04/2021/2fab525c022f0d6c0dba8a6edcf12060.png?width=398&height=320"
            },
            {
                  "name": "Mi 10",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/2461CE83-F535-6B92-CBEE-5971984298E4.jpg?width=398&height=320"
            },
            {
                  "name": "Mi MIX Alpha",
                  "image": "https://i01.appmifile.com/webfile/globalimg/in/cms/FD2BD776-5489-44CA-F05A-C20C232A4848.jpg?width=398&height=320"
            }
      ],
      "footer": {
            "support": [
                  {
                        "name": "Online Help",
                        "url": "https://www.mi.com/in/service/help/#category_id=1&pagenum=1&channel=1"
                  },
                  {
                        "name": "Customer Service",
                        "url": "https://www.mi.com/in/service/online/"
                  },
                  {
                        "name": "Shipping FAQ",
                        "url": "https://www.mi.com/in/service/shipping/"
                  },
                  {
                        "name": "Service Centres",
                        "url": "https://www.mi.com/in/service/repair/"
                  },
                  {
                        "name": "Warrenty",
                        "url": "https://www.mi.com/in/service/warranty/"
                  },
                  {
                        "name": "Mi Exchange",
                        "url": "https://www.mi.com/in/miexchange/"
                  },
                  {
                        "name": "Bulk Order",
                        "url": "https://www.mi.com/in/bulkbuy/"
                  },
                  {
                        "name": "User Guide",
                        "url": "https://www.mi.com/in/service/userguide/"
                  },
                  {
                        "name": "Laptop Drivers",
                        "url": "https://www.mi.com/in/service/support/laptop-drivers.html"
                  },
                  {
                        "name": "Mi Screen Protect",
                        "url": "https://www.mi.com/in/service/miphoneprotection/#miscreenprotect"
                  },
                  {
                        "name": "Mi Extended Warranty",
                        "url": "https://www.mi.com/in/service/miextendedwarranty/"
                  }
            ],
            "shopAndLearn": [
                  {
                        "name": "Mi 10i",
                        "url": "https://www.mi.com/in/mi-10i/"
                  },
                  {
                        "name": "Mi 10T Series",
                        "url": "https://in.event.mi.com/in/mi-10t-series"
                  },
                  {
                        "name": "Mi 11 Ultra",
                        "url": "https://www.mi.com/in/mi-11-ultra/"
                  },
                  {
                        "name": "Mi 11X",
                        "url": "https://www.mi.com/in/mi-11x/"
                  },
                  {
                        "name": "Mi 11X Pro",
                        "url": "https://www.mi.com/in/mi-11x-pro/"
                  },
                  {
                        "name": "Redmi 8",
                        "url": "https://www.mi.com/in/redmi-8/"
                  },
                  {
                        "name": "Redmi 9",
                        "url": "https://www.mi.com/in/redmi-9/"
                  },
                  {
                        "name": "Redmi 9 Prime",
                        "url": "https://www.mi.com/in/redmi-9-prime/"
                  },
                  {
                        "name": "Redmi 9i",
                        "url": "https://www.mi.com/in/redmi-9i/"
                  },
                  {
                        "name": "Redmi 9A",
                        "url": "https://www.mi.com/in/redmi-9a/"
                  },
                  {
                        "name": "Redmi Note 10",
                        "url": "https://www.mi.com/in/redmi-note-10/"
                  },
                  {
                        "name": "Redmi Note 10 Pro",
                        "url": "https://www.mi.com/in/redmi-note-10-pro/"
                  },
                  {
                        "name": "Redmi Note 10 Pro Max",
                        "url": "https://www.mi.com/in/redmi-note-10-pro-max/"
                  },
                  {
                        "name": "Redmi Note 9 Pro",
                        "url": "https://www.mi.com/in/redmi-note-9-pro/"
                  },
                  {
                        "name": "Redmi Note 9",
                        "url": "https://www.mi.com/in/redmi-note-9/"
                  },
                  {
                        "name": "Redmi Note 9 Pro Max",
                        "url": "https://www.mi.com/in/redmi-note-9-pro-max/"
                  },
                  {
                        "name": "TVs",
                        "url": "https://www.mi.com/in/all-mi-tv"
                  },
                  {
                        "name": "Laptops",
                        "url": "https://www.mi.com/in/xiaomi-laptops"
                  }
            ],
            "retailStore": [
                  {
                        "name": "Mi amla_barfi",
                        "url": "https://www.mi.com/in/service/miamla_barfi/"
                  },
                  {
                        "name": "Mi Authorised Stores",
                        "url": "https://www.mi.com/in/service/authorized_stores/"
                  }
            ],
            "aboutUS": [
                  {
                        "name": "Xioami",
                        "url": "https://www.mi.com/in/about/"
                  },
                  {
                        "name": "Mediakit",
                        "url": "https://www.mi.com/in/mediakit/"
                  },
                  {
                        "name": "Culture",
                        "url": "https://www.mi.com/in/about/#culture"
                  },
                  {
                        "name": "User Agreement",
                        "url": "https://www.mi.com/in/about/agreement/"
                  },
                  {
                        "name": "Privacy Policy",
                        "url": "https://www.mi.com/in/about/privacy/"
                  },
                  {
                        "name": "Declarations",
                        "url": "https://www.mi.com/in/service/support/mideclare.html"
                  },
                  {
                        "name": "Integrity & Compliance",
                        "url": "https://integrity.mi.com/global"
                  },
                  {
                        "name": "Smartphone Quality",
                        "url": "https://www.mi.com/in/smartphone-quality/"
                  },
                  {
                        "name": "TV Quality",
                        "url": "https://www.mi.com/in/tv-quality/"
                  },
                  {
                        "name": "Service Quality",
                        "url": "https://www.mi.com/in/service-quality/"
                  },
                  {
                        "name": "Environment",
                        "url": "https://www.mi.com/in/service/support/ewastetakeback.html"
                  },
                  {
                        "name": "Sitemap",
                        "url": "https://www.mi.com/in/sitemap"
                  }
            ],
            "contactUs": [
                  {
                        "name": "Email",
                        "url": "https://in.event.mi.com/in/questionnaire/email_us"
                  },
                  {
                        "name": "Careers",
                        "url": "https://www.mi.com/in/about/#career"
                  }
            ]
      }
}

export default data;